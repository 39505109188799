import React, { useState } from 'react';
import axios from 'axios';
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';
import { useNavigate } from 'react-router-dom';
import "../userDash.css"

const AddSportPost = () => {
  const [sportPost, setSportPost] = useState('');
  const [errors, setErrors] = useState({});

  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);

  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const result = await axios.post('/api/createChatSport', {
        createdBy: {
          id: loggedUser1._id,
          email: loggedUser1.email,
          firstName: loggedUser1.firstName, 
          lastName: loggedUser1.lastName
        },
        sportPost,
        postedOn: new Date(),
      });

      setSportPost('');
      window.location.reload();
    } catch (err) {
      const errorResponse = err.response.data.errors;
      setErrors(errorResponse);
    }
  };

  return (
    <div className="bg-div">
      <div className="rotate">
        <div className="font-quicksand flex min-h-full p-3 pt-5">
          <form
            className="m-auto bg-white drop-shadow-lg overflow-hidden w-full max-w-xl accent-gray-800"
            onSubmit={handleSubmit}
          >
            <div className="p-6">
              <p className="text-2xl pl-3 text-[#012753]">ADD POST</p>

              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <textarea
                  className="w-full h-20 bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800 resize-none"
                  placeholder="Post to the Sports Board"
                  value={sportPost}
                  onChange={(e) => setSportPost(e.target.value)}
                />
                {errors.sportPost && (
                  <p className="text-red-600 text-center">{errors.sportPost.message}</p>
                )}
              </div>

              <input type="hidden" name="userId" value={loggedUser1._id} />
            </div>

            <div>
              <button
                className="bg-[#325490] uppercase py-4 w-full font-quicksand text-white font-bold text-m tracking-widest"
                onClick={() => audio.play()}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <p className="title5">
        *Please note: Your post will be deleted 90 days after posting.
      </p>
    </div>
  );
};

export default AddSportPost;

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';

const UserProfileForm = () => {
  const [profileFirstName, setProfileFirstName] = useState('');
  const [profileLastName, setProfileLastName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [email, setEmail] = useState('');
  const [interests, setInterests] = useState([]);
  const [showOtherInterestInput, setShowOtherInterestInput] = useState(false);
  const [otherInterest, setOtherInterest] = useState('');

  const [errors, setErrors] = useState({});

  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);

  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.post('/api/createUserProfile', {
        createdBy: {
          id: loggedUser1._id,
          email: loggedUser1.email,
        },
        profileFirstName,
        profileLastName,
        phoneNum,
        email,
        interests,
        otherInterest,
      });

      setProfileFirstName('');
      setProfileLastName('');
      setPhoneNum('');
      setEmail('');
      setInterests([]);
      setOtherInterest('');
      setShowOtherInterestInput(false);
      navigate('/myProfile');
    } catch (err) {
      const errorResponse = err.response.data.errors;
      setErrors(errorResponse);
    }
  };

  return (
    <div className="bg-div">
      {/* NEW FORM ########### */}
      <button
        className="jobButton"
        onClick={() => {
          audio.play();
          navigate('/myProfile');
        }}
      >
        Back to My Profile
      </button>
      <div className="rotate">
        <div className="font-quicksand flex min-h-full p-3 pt-5">
          <form
            className="m-auto bg-white drop-shadow-lg rounded-lg overflow-hidden w-full max-w-xl accent-gray-800"
            onSubmit={handleSubmit}
          >
            <div className="p-6">
              <p className="text-3xl pl-3">Add Your Member Profile</p>
              {/* FIRST NAME START  */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="First Name"
                  type="text"
                  value={profileFirstName}
                  onChange={(e) => setProfileFirstName(e.target.value)}
                />
                {errors.profileFirstName ? (
                  <p className="text-red-600 text-center">{errors.firstName.message}</p>
                ) : null}
              </div>
              {/* FIRST NAME END */}

              {/* LAST NAME START  */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Last Name"
                  type="text"
                  value={profileLastName}
                  onChange={(e) => setProfileLastName(e.target.value)}
                />
                {errors.profileLastName ? (
                  <p className="text-red-600 text-center">{errors.lastName.message} </p>
                ) : null}
              </div>

              {/* LAST NAME END  */}
              {/* phonenum START  */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Phone Number (Optional)"
                  type="text"
                  value={phoneNum}
                  onChange={(e) => setPhoneNum(e.target.value)}
                />
              </div>
              {/* phoneNum END  */}
              {/* EMAIL START  */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Email (Optional)"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* EMAIL END */}
              {/* INTERESTS START */}
              <div className="mt-4">
                <label className="block text-sm font-bold text-gray-700">Interests</label>
                <div className="flex flex-wrap gap-4">
                  {['Cooking/Baking', 'Health & Wellness', 'Reading & Books', 'Outdoor Recreation', 'Sports Enthusiast', 'Home Improvement', 'Gardening', 'Travel'].map((interest) => (
                    <div key={interest} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`interest_${interest}`}
                        name="interests"
                        value={interest}
                        checked={interests.includes(interest)}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setInterests((prevInterests) => {
                            if (checked) {
                              return [...prevInterests, interest];
                            } else {
                              return prevInterests.filter((item) => item !== interest);
                            }
                          });
                        }}
                      />
                      <label htmlFor={`interest_${interest}`} className="ml-2">
                        {interest}
                      </label>
                    </div>
                  ))}
                  {/* OTHER INTEREST START */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="interest_other"
                      name="interests"
                      checked={showOtherInterestInput}
                      onChange={() => setShowOtherInterestInput(!showOtherInterestInput)}
                    />
                    <label htmlFor="interest_other" className="ml-2">
                      Other
                    </label>
                    {showOtherInterestInput && (
                      <input
                        type="text"
                        className="ml-2 w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                        placeholder="Enter other interests"
                        value={otherInterest}
                        onChange={(e) => setOtherInterest(e.target.value)}
                      />
                    )}
                  </div>
                  {/* OTHER INTEREST END */}
                </div>
              </div>
              {/* INTERESTS END */}
            </div>
            <div>
              <button className="bg-[#325490] uppercase py-4 w-full font-quicksand text-white font-bold text-m tracking-widest"
                onClick={() => audio.play()}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfileForm;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../forum.css";
import AddTravelPost from './AddTravelPost';
import AddTravelReply from './AddTravelReply';
import Modal from './Modal'; // Assuming you have a Modal component

const ChatTravel = () => {
    const [list, setList] = useState([]);
    const [repliesMap, setRepliesMap] = useState({});
    const [repliesVisibility, setRepliesVisibility] = useState({});
    const [showAddPostModal, setShowAddPostModal] = useState(false);
    const [showAddReplyModal, setShowAddReplyModal] = useState(false);
    const [newPostContent, setNewPostContent] = useState('');
    const [selectedPostId, setSelectedPostId] = useState(null);

    const localUser = localStorage.getItem('loggedUser');
    const loggedUser1 = JSON.parse(localUser);
    const { id } = useParams();
    const navigate = useNavigate();

    const filterExpiredPosts = (travelPosts) => {
        const currentTime = new Date().getTime();
        return travelPosts.filter((travelPost) => {
            const recPostedTime = new Date(travelPost.postedOn).getTime();
            const daysDifference = (currentTime - recPostedTime) / (1000 * 60 * 60 * 24);
            return daysDifference <= 90;
        });
    };

    const toggleRepliesVisibility = (postId) => {
        setRepliesVisibility((prevVisibility) => ({
            ...prevVisibility,
            [postId]: !prevVisibility[postId],
        }));
    };

    useEffect(() => {
        const fetchAndFilterTravelPosts = async () => {
            try {
                const res = await axios.get('/api/getAllChatTravels', { withCredentials: true });
                const sortedList = res.data.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
                const nonExpiredTravelPosts = filterExpiredPosts(sortedList);
                setList(nonExpiredTravelPosts);

                const promises = nonExpiredTravelPosts.map(async (post) => {
                    try {
                        const response = await axios.get(`/api/getRepliesForTravelPost/${post._id}`);
                        setRepliesMap((prevRepliesMap) => ({
                            ...prevRepliesMap,
                            [post._id]: response.data,
                        }));
                        setRepliesVisibility((prevVisibility) => ({
                            ...prevVisibility,
                            [post._id]: false,
                        }));
                    } catch (error) {
                        console.error('Error fetching replies:', error);
                    }
                });

                await Promise.all(promises);
                    // Check if there are new posts and set localStorage flag
                    const currentPostCount = localStorage.getItem('travelPostCount') || 0;
                    if (nonExpiredTravelPosts.length > currentPostCount) {
                        localStorage.setItem('newTravelPosts', 'true');
                    }
                    localStorage.setItem('travelPostCount', nonExpiredTravelPosts.length);
            } catch (err) {
                console.log(err);
            }
        };

        fetchAndFilterTravelPosts();

        const interval = setInterval(() => {
            fetchAndFilterTravelPosts();
        }, 24 * 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const deleteTravelPost = (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this post?');
        if (confirmation) {
            axios
                .delete(`/api/deleteChatTravel/${id}`)
                .then((res) => {
                    console.log('Travel post deleted', res.data);
                    setList((prevList) => prevList.filter((travelPost) => travelPost._id !== id));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleAddPost = () => {
        setShowAddPostModal(true);
    };

    const handleAddReply = (postId) => {
        setSelectedPostId(postId);
        setShowAddReplyModal(true);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getRepliesForPost = async (postId) => {
        try {
            const response = await axios.get(`/api/getRepliesForTravelPost/${postId}`);
            console.log('Replies for Post:', response.data);
        } catch (error) {
            console.error('Error fetching replies:', error);
        }
    };

    const handleAddPostFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a post
        setShowAddPostModal(false);
        // Call API to add the post
    };

    const handleAddReplyFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a reply
        setShowAddReplyModal(false);
        // Call API to add the reply
    };

    const deleteReply = (postId, replyId) => {
        const confirmation = window.confirm('Are you sure you want to delete this reply?');
        if (confirmation) {
            axios
                .delete(`/api/deleteChatTravelReply/${replyId}`)
                .then((res) => {
                    console.log('Reply deleted', res.data);
                    setRepliesMap((prevRepliesMap) => ({
                        ...prevRepliesMap,
                        [postId]: prevRepliesMap[postId].filter((reply) => reply._id !== replyId),
                    }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="forumMain">
            <h2 className="forumTitle">Travel</h2>
            <p className="forumTitle2">Explore&nbsp;&nbsp;|&nbsp; &nbsp;Wander&nbsp;&nbsp;|&nbsp;&nbsp;Discover</p>
            <button onClick={handleAddPost}
                className="forumButton bg-[#325490] mt-4 py-4 w-full font-quicksand text-white text-m tracking-widest"
                type="submit"
              >
                ADD POST
              </button>

            <Modal show={showAddPostModal} onClose={() => setShowAddPostModal(false)}>
                    <AddTravelPost />
                    <button type="button" onClick={() => setShowAddPostModal(false)}>Cancel</button>
            </Modal>
            <div className="forumBox">
                {list.map((travelPost, index) => (
                    <div className="forumPost" key={`post_${travelPost._id}_${index}`}>
                        <div className="postHeader">
                            <div className="posterInfo">
                                <span>
                                    {travelPost.createdBy && travelPost.createdBy.firstName} {' '}
                                    {travelPost.createdBy && travelPost.createdBy.lastName} {' '}
                                    posted on {' '} {formatDate(travelPost.postedOn)}
                                </span>
                            </div>
                            {loggedUser1 && loggedUser1.email === travelPost.createdBy?.email && (
                                <span className="clickText" onClick={() => deleteTravelPost(travelPost._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="postContent">
                            {travelPost.travelPost}
                        </div>
                        <button onClick={() => toggleRepliesVisibility(travelPost._id)}>
                            {repliesVisibility[travelPost._id] ? 'Hide Replies' : 'Show Replies'}
                        </button>
                        {repliesVisibility[travelPost._id] && (
                            <div className="repliesContainer">
                    <strong>Replies:</strong>
            {repliesMap[travelPost._id] && repliesMap[travelPost._id].length > 0 ? (
            <ul>
                {repliesMap[travelPost._id].map((reply, index) => (
                    <li className='replySinglePost' key={`reply_${travelPost._id}_${reply._id || index}`}>
                        <div className="replyHeader">
                            <span className="replyPosterInfo">
                                {reply.createdBy.firstName} {reply.createdBy.lastName} replied on{' '}
                                {reply.createdAt ? formatDate(reply.createdAt) : 'N/A'}
                            </span>
                            {loggedUser1 && loggedUser1.email === reply.createdBy?.email && (
                                <span className="clickText2" onClick={() => deleteReply(travelPost._id, reply._id)}>
                                    delete
                                </span>
                            )}
                        </div>

                        <div className="replyContent">
                            <span className="replyTextForum">{reply.replyText}</span>
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <span className="replyTextForum">No replies yet.</span>
        )}
    </div>
)}
                        <AddTravelReply
                            postId={travelPost._id}
                            setList={setList}
                            getRepliesForPost={getRepliesForPost}
                        />
                    </div>
                ))}
            </div>
            <div id="modal-root"></div>
        </div>
    );
};


export default ChatTravel;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';

const Todoform = () => {
  const [task, setTask] = useState('');
  const [errors, setErrors] = useState({});
  const [todolist, setTodolist] = useState([]); // Moved state here

  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);

  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  const fetchTodoList = async () => {
    try {
      const response = await axios.get('/api/getAllTodos', {
        withCredentials: true,
      });
      setTodolist(response.data);
    } catch (error) {
      console.error('Error fetching todo list:', error);
    }
  };

  useEffect(() => {
    fetchTodoList();
  }, []); // Fetch the initial list of tasks when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.post('/api/createTodo', {
        createdBy: {
          id: loggedUser1._id,
          email: loggedUser1.email,
        },
        task,
        completed: false,
      });

      setTask('');
      fetchTodoList(); // Fetch updated task list after adding a task
      navigate('/dashboard');
    } catch (err) {
      const errorResponse = err.response.data.errors;
      setErrors(errorResponse);
    }
  };

  return (
    <div className="font-quicksand flex min-h-full p-3 pt-5">
      <form
        className="m-auto bg-white drop-shadow-lg rounded-lg overflow-hidden w-full max-w-md accent-gray-800"
        onSubmit={handleSubmit}
      >
        <div className="p-6">
          <p className="text-3xl pl-3 text-[#012753]">Add a Task</p>
          <div className="mt-4 relative">
            <input
              className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-4 rounded placeholder:text-gray-800"
              placeholder="Add a task!"
              type="text"
              value={task}
              onChange={(e) => setTask(e.target.value)}
            />
            {errors.task && (
              <p className="text-red-600 text-center">{errors.task.message}</p>
            )}
          </div>
          <button
            className="bg-[#325490] uppercase py-4 w-full font-quicksand text-white font-bold text-m tracking-widest"
            onClick={() => audio.play()}
          >
            Submit
          </button>
        </div>
      </form>
      <div>
        {/* Render your todolist here */}
      </div>
    </div>
  );
};

export default Todoform;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddBbPost from './AddBbPost';
import BulletinBoard from './BulletinBoard';
import "../userDash.css";
import Todolist from './Todolist';
import AddBbReply from './AddBbReply';
import SmallCalendar from './SmallCalendar';
import DailyWeather from './DailyWeather';

const UserDashboard = ({user}) => {
  const [quote, setQuote] = useState({ text: '', author: '' });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [usHoliday, setUsHoliday] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [lastFetchedDate, setLastFetchedDate] = useState('');


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date()); // Update currentDate every day
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
  }, []); // Runs once on component mount

  useEffect(() => {
    const fetchNaturePicture = async () => {
      try {
        const response = await axios.get('https://api.pexels.com/v1/search?query=nature&per_page=1', {
          headers: {
            Authorization: '9kq2wllhtWPtti5tWWs8nn5bYRLIjUY65iWJVkIXp8JjIIUsMH55jleL'
          }
        });

        const pictureUrl = response.data.photos[0].src.large;
        setImageUrl(pictureUrl);
      } catch (error) {
        console.error('Error fetching nature picture:', error);
      }
    };


    

    // Calculate the milliseconds until the next midnight
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    const millisecondsUntilMidnight = midnight - new Date();

    // Fetch the nature picture immediately
    fetchNaturePicture();

    // Fetch a new nature picture at the start of each day
    const intervalId = setInterval(() => {
      fetchNaturePicture();
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    // Fetch a new nature picture after reaching the next midnight
    const timeoutId = setTimeout(() => {
      fetchNaturePicture();

      // Reset the interval to update at the start of each day
      setInterval(() => {
        fetchNaturePicture();
      }, 24 * 60 * 60 * 1000); // Update every 24 hours
    }, millisecondsUntilMidnight);

    // Cleanup functions to clear timeout and interval on unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);


  // Get day and date from the current date
  const day = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
  const date = currentDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });


  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const storedQuote = localStorage.getItem('dailyQuote');
        const storedDate = localStorage.getItem('quoteDate');
        const today = new Date().toLocaleDateString();
  
        if (storedQuote && storedDate === today) {
          // If quote is stored in localStorage and it's the same day, use it
          setQuote(JSON.parse(storedQuote));
        } else {
          // Fetch new quote from the API
          const options = {
            method: 'GET',
            url: 'https://famous-quotes4.p.rapidapi.com/random',
            params: {
              category: 'all',
              count: '1'
            },
            headers: {
              'X-RapidAPI-Key': 'ddf4d2da2bmsh2459e1086602079p1372e0jsn9230d91981af',
              'X-RapidAPI-Host': 'famous-quotes4.p.rapidapi.com'
            }
          };
  
          const response = await axios.request(options);
          const responseData = response.data[0];
          const newQuote = { text: responseData.text, author: responseData.author };
          setQuote(newQuote);
          // Store the new quote and current date in localStorage
          localStorage.setItem('dailyQuote', JSON.stringify(newQuote));
          localStorage.setItem('quoteDate', today);
        }
      } catch (error) {
        console.error('Error fetching quote:', error);
      }
    };
  
    fetchQuote(); // Fetch quote when the component mounts
  
    // Set up interval to fetch new quote every 24 hours
    const intervalId = setInterval(() => {
      fetchQuote();
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); 

  
  return (
    <div className='user-dash-container'>
      <div className='dashSideLeft'>
        <h2 className='dashTitle'>Welcome to the Clubhouse!</h2>
        <div>
          <h3 className='h3Dash'>{`${day}, ${date}`}</h3>
        </div>
        <div className="quote-of-the-day">
          <p>"{quote.text}" - {quote.author}</p>
        </div>
        {/* <div className='natureImg'>
          {imageUrl && <img src={imageUrl} alt="Nature" style={{ maxWidth: '50%' }} />}
        </div> */}
        <SmallCalendar />
        <h3 className='h3Dash'>Daily Weather</h3>
        <DailyWeather />
      </div>
      <div className='dashSideRight'>
        <BulletinBoard />
        <AddBbPost />
      </div>
    </div>
  )
}

export default UserDashboard;

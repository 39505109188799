import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import alert from '../assets/alert.svg';
import book from '../assets/book.svg';
import health from '../assets/health.svg';
import cooking from '../assets/cooking.svg';
import hobby from '../assets/hobby.svg';
import house from '../assets/house.svg';
import outdoor from '../assets/outdoor.svg';
import sport from '../assets/sport.svg';
import travel from '../assets/travel.svg';
import star from '../assets/star.svg';
import sell from '../assets/sell.svg';

import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';
import "../chatterbox.css";

const ChatterboxHome = () => {

    const navigate = useNavigate();
    const audio = new Audio(buttonSound);
    const localUser = localStorage.getItem('loggedUser');
    const loggedUser1 = JSON.parse(localUser);

    const [latestBookPost, setLatestBookPost] = useState(null);
    const [latestKitchenPost, setLatestKitchenPost] = useState(null);
    const [latestHobbyPost, setLatestHobbyPost] = useState(null);
    const [latestNeighborPost, setLatestNeighborPost] = useState(null);
    const [latestHealthPost, setLatestHealthPost] = useState(null);
    const [latestRecPost, setLatestRecPost] = useState(null);
    const [latestSportPost, setLatestSportPost] = useState(null);
    const [latestTravelPost, setLatestTravelPost] = useState(null);
    const [latestMiscPost, setLatestMiscPost] = useState(null);
    const [latestSellPost, setLatestSellPost] = useState(null);


    const [loading, setLoading] = useState(true);


const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

  useEffect(() => {
    // Fetch latest book post data
    const fetchLatestBookPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatBook');
            const data = await response.json();
            setLatestBookPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest book post:', error);
            setLoading(false);
        }
    };

    const fetchLatestKitchenPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatKitchen');
            const data = await response.json();
            setLatestKitchenPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest kitchen post:', error);
            setLoading(false);
        }
    };

    const fetchLatestHobbyPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatHobby');
            const data = await response.json();
            setLatestHobbyPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest hobby post:', error);
            setLoading(false);
        }
    };

    const fetchLatestNeighborPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatNeighbor');
            const data = await response.json();
            setLatestNeighborPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest home improvement post:', error);
            setLoading(false);
        }
    };

    const fetchLatestHealthPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatHealth');
            const data = await response.json();
            setLatestHealthPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest health post:', error);
            setLoading(false);
        }
    };

    const fetchLatestRecPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatRec');
            const data = await response.json();
            setLatestRecPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest rec post:', error);
            setLoading(false);
        }
    };

    const fetchLatestSportPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatSport');
            const data = await response.json();
            setLatestSportPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest sport post:', error);
            setLoading(false);
        }
    };

    const fetchLatestTravelPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatTravel');
            const data = await response.json();
            setLatestTravelPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest travel post:', error);
            setLoading(false);
        }
    };

    const fetchLatestMiscPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatMisc');
            const data = await response.json();
            setLatestMiscPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest misc post:', error);
            setLoading(false);
        }
    };

    const fetchLatestSellPost = async () => {
        try {
            const response = await fetch('/api/getLatestChatSell');
            const data = await response.json();
            setLatestSellPost(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching latest sell post:', error);
            setLoading(false);
        }
    };

    fetchLatestBookPost();
    fetchLatestKitchenPost();
    fetchLatestHobbyPost();
    fetchLatestNeighborPost();
    fetchLatestHealthPost();
    fetchLatestRecPost();
    fetchLatestSportPost();
    fetchLatestTravelPost();
    fetchLatestMiscPost();
    fetchLatestSellPost();


}, []);

if (loading) {
    return <div>Loading...</div>;
}

  return (
    <div className='chatterMain'>
        <div>
            <h2 className='chatTitle'>The Chatterbox</h2>

            <p className='chatTitle2'>Spark Conversations. Cultivate Connections. Watch Community Bloom.</p>

                <div className='flex flex-col items-center justify-center'>
                    <div className='chatBoxDiv'>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/books") }}>
                            <h3 className='categoryTitle' >Books & Reading</h3>
                        {latestBookPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7] p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={book} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestBookPost.createdBy.firstName} {latestBookPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestBookPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/kitchen") }}>
                            <h3 className='categoryTitle' >Cooking & Baking</h3>
                            {latestKitchenPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={cooking} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestKitchenPost.createdBy.firstName} {latestKitchenPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestKitchenPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className='chatBoxDiv'>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/hobbies") }}>
                            <h3 className='categoryTitle' >Hobbies</h3>
                            {latestHobbyPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={hobby} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestHobbyPost.createdBy.firstName} {latestHobbyPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestHobbyPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                        <div className='singleBox'  onClick={() => { audio.play(); navigate("/chatterbox/homeimprovement") }}>
                            <h3 className='categoryTitle'>Home Improvement</h3>
                            {latestNeighborPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={house} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestNeighborPost.createdBy.firstName} {latestNeighborPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestNeighborPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>
<div className='chatBoxDiv'>
    
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/health") }}>
                            <h3 className='categoryTitle' >Health & Wellness</h3>
                            {latestHealthPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={health} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestHobbyPost.createdBy.firstName} {latestHobbyPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestHobbyPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/outdoorrec") }}>
                            <h3 className='categoryTitle' >Outdoor Recreation</h3>
                            {latestRecPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={outdoor} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestRecPost.createdBy.firstName} {latestRecPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestRecPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
</div>

                    <div className='chatBoxDiv'>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/sports") }}>
                            <h3 className='categoryTitle' >Sports Enthusiast</h3>
                            {latestSportPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={sport} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestSportPost.createdBy.firstName} {latestSportPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestSportPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/travel") }}>
                            <h3 className='categoryTitle' >Travel</h3>
                            {latestTravelPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={travel} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestTravelPost.createdBy.firstName} {latestTravelPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestTravelPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className='chatBoxDiv'>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/miscellaneous") }}>
                            <h3 className='categoryTitle' >Miscellaneous</h3>
                            {latestMiscPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={star} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestMiscPost.createdBy.firstName} {latestMiscPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestMiscPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                        <div className='singleBox' onClick={() => { audio.play(); navigate("/chatterbox/marketplace") }}>
                            <h3 className='categoryTitle' >Marketplace</h3>
                            {latestSellPost && (
                                    <div className='flex flex-row items-center justify-center border border-dotted border-[#AABFD7]  p-2 m-2'>
                                        <img className="newPostsAlert mr-4" src={sell} alt="Alert"></img>
                                        <p className="newPostInfo">
                                            Latest post by {latestSellPost.createdBy.firstName} {latestSellPost.createdBy.lastName} on <span className='font-bold'>{formatDate(latestSellPost.postedOn)}</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>

                </div>
        </div>

    </div>

  )
}

export default ChatterboxHome


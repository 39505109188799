import React, { useState, useEffect } from 'react'
import {Link,NavLink, useNavigate} from 'react-router-dom';
import '../navbar.css'
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav'
import clubLogo from '../assets/clubhouselogo4.svg'
import { RxAvatar } from "react-icons/rx";

const Navbar = (props) => {
  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLogout = () => {
      localStorage.clear();
      navigate('/');
  };

  return (
      <div className="navbar">
          {mobileMenuOpen && (
              <div className="mobile-menu-overlay" onClick={() => setMobileMenuOpen(false)}>
                  <div className="mobile-menu">
                      <ul className="mobile-menu-list">
                          {loggedUser1 ? (
                              <>
                                  <li className="mobile-menu-item" >
                                      <Link to={`/user/${loggedUser1._id}`}>
                                          <RxAvatar size={30}/>
                                      </Link>
                                  </li>
                                  <li className="mobile-menu-item">
                                      <button onClick={() => { audio.play(); navigate('/chatterbox/bulletinBoard'); }}>
                                          BULLETIN BOARD
                                      </button>
                                  </li>
                                  <li className="mobile-menu-item">
                                      <button onClick={() => { audio.play(); navigate('/chatterbox'); }}>
                                          CHATTERBOX
                                      </button>
                                  </li>
                                  <li className="mobile-menu-item">
                                      <button onClick={() => { audio.play(); navigate('/members'); }}>
                                          MEMBERS
                                      </button>
                                  </li>
                                  <li className="mobile-menu-item">
                                      <a
                                          href="https://www.wyomissingmeadows.net/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() => audio.play()}
                                      >
                                          WM HOA
                                      </a>
                                  </li>
                                  <li className="mobile-menu-item">
                                      <button onClick={() => { audio.play(); handleLogout(); }}>
                                          LOGOUT
                                      </button>
                                  </li>
                              </>
                          ) : (
                              <>
                                  <li className="mobile-menu-item">
                                      <button onClick={() => { audio.play(); navigate('/login'); }}>
                                          LOGIN
                                      </button>
                                  </li>
                                  <li className="mobile-menu-item">
                                      <a
                                          href="https://www.wyomissingmeadows.net/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() => audio.play()}
                                      >
                                          WM HOA
                                      </a>
                                  </li>
                              </>
                          )}
                      </ul>
                  </div>
              </div>
          )}

          <div className="mobile-menu-button" onClick={() => { audio.play(); setMobileMenuOpen(!mobileMenuOpen) }}>
              <div className={`hamburger ${mobileMenuOpen ? 'open' : ''}`}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
              </div>
          </div>

          {loggedUser1 ? (
              <img className='plainLogo' src={clubLogo} alt="laptop img" onClick={() => { audio.play(); navigate("/dashboard") }}></img>
          ) : (
              <img className='plainLogo' src={clubLogo} alt="laptop img" onClick={() => { audio.play(); navigate("/") }}></img>
          )}

          <div className='centerNav'>
            {loggedUser1 ? (<img src={clubLogo} alt="The Clubhouse" onClick={() => { audio.play(); navigate("/dashboard") }} />) : (<img src={clubLogo} alt="The Clubhouse" onClick={() => { audio.play(); navigate("/") }} />)}
          </div>


<div className='leftNav'>

	{loggedUser1 ? ( 
		<ul className='navList1 flex lg:flex-row md:flex-row'>
			  <div className='rowNav1'>
              <li className="listItem">
                              <button className="navButton" onClick={() => { audio.play(); navigate('/chatterbox'); }}>
                                  CHATTERBOX
                              </button>
                          </li>
                        <li className="listItem">
                              <button className="navButton" onClick={() => { audio.play(); navigate('/members'); }}>
                                  MEMBERS
                              </button>
                          </li>
                          <li className="listItem">
                          <Link to={`/user/${loggedUser1._id}`}>
          <button className="navButton" onClick={() => { audio.play() }}>
            <RxAvatar size={25} className='avatar'/>
          </button>
        </Link>

                          </li>
              </div>
              <div className='rowNav2'>
              <li className="listItem">
                              <button className="navButton" onClick={() => { audio.play(); navigate('/chatterbox/bulletinBoard'); }}>
                                  BULLETIN BOARD
                              </button>
                          </li>
                  <li className='listItem'>
                      <a href="https://www.wyomissingmeadows.net/" target="_blank" rel="noopener noreferrer">
                          <button className="navButton" onClick={() => { audio.play() }}>WM HOA</button>
                      </a>
                  </li>
                  <li className='listItem'>
                          <button className="navButton" onClick={() => { audio.play(); handleLogout(); }}>
                              LOGOUT
                          </button>
                  </li>		

                </div>
               </ul>)
 : ( 
<ul className='navList flex lg:flex-row md:flex-row'>
			   <li className="listItem">
                          <button
                              className="navButton"
                              onClick={() => {
                                  audio.play();
                                  navigate('/login');
                              }}
                          >
                              LOGIN
                          </button>
                          </li>
                  <li className='listItem'>
                      <a href="https://www.wyomissingmeadows.net/" target="_blank" rel="noopener noreferrer">
                          <button className="navButton" onClick={() => { audio.play() }}>WM HOA</button>
                      </a>
                  </li>		
               </ul>
) }
</div>

      </div>
  );
}

export default Navbar;

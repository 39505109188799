import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import EditUser from './EditUser';
import "../userProfile.css"
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav'


const UserProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);

  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  useEffect(() => {
    axios
      .get(`/api/user/${id}`, { withCredentials: true })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  if (!user) {
    // Loading state or redirect to an error page
    return <div>Loading...</div>;
  }

  const handleProfileUpdate = () => {
    // Refetch user data after the profile is updated
    axios
      .get(`/api/user/${id}`, { withCredentials: true })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
{loggedUser1._id === user._id ? (
    <div className="user-profile-container">
        <div className='profileSide'>
      <h2 className='profTitle'>My Member Profile</h2>
      <p className='profPara'>
        <strong>First Name:</strong> {user.firstName}
      </p>
      <p className='profPara'>
        <strong>Last Name:</strong> {user.lastName}
      </p>
      <p className='profPara'>
        <strong>Email:</strong> {user.email}
      </p>
      <p className='profPara'>
        <strong>House Number:</strong> {user.houseNum}
      </p>
      <p className='profPara'>
        <strong>Phone Number:</strong> {user.phoneNum || 'Not provided'}
      </p>
      <p className='profPara'>
        <strong>Interests:</strong> {user.interests.join(', ')}
      </p>

        <p className='profPara'>
          <strong>Other Interests:</strong> {user.otherInterest}
        </p>

      {/* Add more fields as needed */}
      </div>
      <div>

      
          <EditUser onProfileUpdate={handleProfileUpdate}/>
       

      </div>
 

    </div>
) : <div className="user-profile-container2">
<div className='profileSide'>
<h2 className='profTitle'>Member Profile</h2>
<p className='profPara'>
<strong>First Name:</strong> {user.firstName}
</p>
<p className='profPara'>
<strong>Last Name:</strong> {user.lastName}
</p>

<p className='profPara'>
<strong>Phone Number:</strong> {user.phoneNum || 'Not provided'}
</p>
<p className='profPara'>
<strong>Interests:</strong> {user.interests.join(', ')}
</p>

<p className='profPara'>
  <strong>Other Interests:</strong> {user.otherInterest}
</p>

{/* Add more fields as needed */}
</div>

<div className='userProfButton'>
                              <button className="navButton" onClick={() => { audio.play(); navigate('/members'); }}>
                                  BACK TO MEMBERS
                              </button>
</div>

</div>}
    </div>
  );
};

export default UserProfile;
import React, { useState, useEffect } from 'react';
import '../calendar.css'

const SmallCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date()); // Update currentDate every day
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
  }, []); // Runs once on component mount

  const renderCalendar = () => {
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const daysInMonth = lastDayOfMonth.getDate();
    const startingDay = firstDayOfMonth.getDay();

    const calendarDays = [];
    let day = 1;

    for (let i = 0; i < 6; i++) {
      const calendarWeek = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startingDay) {
          calendarWeek.push(<div key={`${i}-${j}`} className="calendar-day"></div>);
        } else if (day <= daysInMonth) {
          const dayClass = day === currentDate.getDate() ? 'current-day' : '';
          calendarWeek.push(<div key={`${i}-${j}`} className={`calendar-day ${dayClass}`}>{day}</div>);
          day++;
        } else {
          calendarWeek.push(<div key={`${i}-${j}`} className="calendar-day"></div>);
        }
      }
      calendarDays.push(<div key={i} className="calendar-week">{calendarWeek}</div>);
      if (day > daysInMonth) break;
    }

    return (
      <div className="small-calendar">
        <h4 className='calTitle'>{currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}</h4>
        <div className="calendar-header">
          <div className="calendar-day">Sun</div>
          <div className="calendar-day">Mon</div>
          <div className="calendar-day">Tue</div>
          <div className="calendar-day">Wed</div>
          <div className="calendar-day">Thu</div>
          <div className="calendar-day">Fri</div>
          <div className="calendar-day">Sat</div>
        </div>
        <div className="calendar-body">
          {calendarDays}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderCalendar()}
    </div>
  );
};

export default SmallCalendar;

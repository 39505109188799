
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../forum.css";
import AddBbPost from './AddBbPost';
import AddBbReply from './AddBbReply';
import Modal from './Modal'; // Assuming you have a Modal component

const BulletinBoardChatter = () => {
    const [list, setList] = useState([]);
    const [repliesMap, setRepliesMap] = useState({});
    const [repliesVisibility, setRepliesVisibility] = useState({});
    const [showAddPostModal, setShowAddPostModal] = useState(false);
    const [showAddReplyModal, setShowAddReplyModal] = useState(false);
    const [newPostContent, setNewPostContent] = useState('');
    const [selectedPostId, setSelectedPostId] = useState(null);

    const localUser = localStorage.getItem('loggedUser');
    const loggedUser1 = JSON.parse(localUser);
    const { id } = useParams();
    const navigate = useNavigate();

    const filterExpiredPosts = (bulletinPosts) => {
        const currentTime = new Date().getTime();
        return bulletinPosts.filter((bulletinPost) => {
            const bulletinPostedTime = new Date(bulletinPost.postedOn).getTime();
            const daysDifference = (currentTime - bulletinPostedTime) / (1000 * 60 * 60 * 24);
            return daysDifference <= 90;
        });
    };

    const toggleRepliesVisibility = (postId) => {
        setRepliesVisibility((prevVisibility) => ({
            ...prevVisibility,
            [postId]: !prevVisibility[postId],
        }));
    };

    useEffect(() => {
        const fetchAndFilterBulletinPosts = async () => {
            try {
                const res = await axios.get('/api/getAllBulletinBoards', { withCredentials: true });
                const sortedList = res.data.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
                const nonExpiredBulletinPosts = filterExpiredPosts(sortedList);
                setList(nonExpiredBulletinPosts);

                const promises = nonExpiredBulletinPosts.map(async (post) => {
                    try {
                        const response = await axios.get(`/api/getRepliesForPost/${post._id}`);
                        setRepliesMap((prevRepliesMap) => ({
                            ...prevRepliesMap,
                            [post._id]: response.data,
                        }));
                        setRepliesVisibility((prevVisibility) => ({
                            ...prevVisibility,
                            [post._id]: false,
                        }));
                    } catch (error) {
                        console.error('Error fetching replies:', error);
                    }
                });

                await Promise.all(promises);
            } catch (err) {
                console.log(err);
            }
        };

        fetchAndFilterBulletinPosts();

        const interval = setInterval(() => {
            fetchAndFilterBulletinPosts();
        }, 24 * 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const deleteBulletinPost = (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this bulletin board post?');
        if (confirmation) {
            axios
                .delete(`/api/deleteBulletinBoard/${id}`)
                .then((res) => {
                    console.log('BB post deleted', res.data);
                    setList((prevList) => prevList.filter((bulletinPost) => bulletinPost._id !== id));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleAddPost = () => {
        setShowAddPostModal(true);
    };

    const handleAddReply = (postId) => {
        setSelectedPostId(postId);
        setShowAddReplyModal(true);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getRepliesForPost = async (postId) => {
        try {
            const response = await axios.get(`/api/getRepliesForPost/${postId}`);
            console.log('Replies for Post:', response.data);
        } catch (error) {
            console.error('Error fetching replies:', error);
        }
    };

    const handleAddPostFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a post
        setShowAddPostModal(false);
        // Call API to add the post
    };

    const handleAddReplyFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a reply
        setShowAddReplyModal(false);
        // Call API to add the reply
    };

    const deleteReply = (postId, replyId) => {
        const confirmation = window.confirm('Are you sure you want to delete this reply?');
        if (confirmation) {
            axios
                .delete(`/api/deleteBulletinBoardReply/${replyId}`)
                .then((res) => {
                    console.log('Reply deleted', res.data);
                    setRepliesMap((prevRepliesMap) => ({
                        ...prevRepliesMap,
                        [postId]: prevRepliesMap[postId].filter((reply) => reply._id !== replyId),
                    }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="forumMain">
            <h2 className="forumTitle">Bulletin Board</h2>
            <p className="forumTitle2">Community Events&nbsp;&nbsp;|&nbsp; &nbsp;Important Announcements&nbsp;&nbsp;|&nbsp;&nbsp;HOA Info</p>
            <button onClick={handleAddPost}
                className="forumButton bg-[#325490] mt-4 py-4 w-full font-quicksand text-white text-m tracking-widest"
                type="submit"
              >
                ADD POST
              </button>

            <Modal show={showAddPostModal} onClose={() => setShowAddPostModal(false)}>
                    <AddBbPost />
                    <button type="button" onClick={() => setShowAddPostModal(false)}>Cancel</button>
            </Modal>
            <div className="forumBox">
                {list.map((bulletinPost, index) => (
                    <div className="forumPost" key={`post_${bulletinPost._id}_${index}`}>
                        <div className="postHeader">
                            <div className="posterInfo">
                                <span>
                                    {bulletinPost.createdBy && bulletinPost.createdBy.firstName} {' '}
                                    {bulletinPost.createdBy && bulletinPost.createdBy.lastName} {' '}
                                    posted on {' '} {formatDate(bulletinPost.postedOn)}
                                </span>
                            </div>
                            {loggedUser1 && loggedUser1.email === bulletinPost.createdBy?.email && (
                                <span className="clickText" onClick={() => deleteBulletinPost(bulletinPost._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="postContent">
                            {bulletinPost.bulletinPost}
                        </div>
                        <button onClick={() => toggleRepliesVisibility(bulletinPost._id)}>
                            {repliesVisibility[bulletinPost._id] ? 'Hide Replies' : 'Show Replies'}
                        </button>
                        {repliesVisibility[bulletinPost._id] && (
                            <div className="repliesContainer">
                    <strong>Replies:</strong>
            {repliesMap[bulletinPost._id] && repliesMap[bulletinPost._id].length > 0 ? (
            <ul>
                {repliesMap[bulletinPost._id].map((reply, index) => (
                    <li className='replySinglePost' key={`reply_${bulletinPost._id}_${reply._id || index}`}>
                        <div className="replyHeader">
                            <span className="replyPosterInfo">
                                {reply.createdBy.firstName} {reply.createdBy.lastName} replied on{' '}
                                {reply.createdAt ? formatDate(reply.createdAt) : 'N/A'}
                            </span>
                            {loggedUser1 && loggedUser1.email === reply.createdBy?.email && (
                                <span className="clickText2" onClick={() => deleteReply(bulletinPost._id, reply._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="replyContent">
                            <span className="replyTextForum">{reply.replyText}</span>
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <span className="replyTextForum">No replies yet.</span>
        )}
    </div>
)}
                        <AddBbReply
                            postId={bulletinPost._id}
                            setList={setList}
                            getRepliesForPost={getRepliesForPost}
                        />
                    </div>
                ))}
            </div>
            <div id="modal-root"></div>
        </div>
    );
};


export default BulletinBoardChatter;

import React from 'react';
import ReactDOM from 'react-dom';
import '../modal.css'; // Assuming you have a modal stylesheet

const Modal = ({ show, children, onClose }) => {
    if (!show) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>
                    X
                </button>
                {children}
            </div>
        </div>,
        document.getElementById('modal-root') // Assuming you have a div with id 'modal-root' in your HTML file
    );
};

export default Modal;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';
import { useParams, useNavigate } from 'react-router-dom';
import "../userDash.css";

const AddSportReply = ({ postId, setList }) => {
  const [replyText, setReplyText] = useState('');
  const [errors, setErrors] = useState({});
  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);
  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const result = await axios.post(`/api/createChatSportReply/${postId}`, {
        createdBy: {
          id: loggedUser1._id,
          email: loggedUser1.email,
          firstName: loggedUser1.firstName,
          lastName: loggedUser1.lastName
        },
        replyText,
      });

      // Update the state with the new reply
      setList((prevList) => {
        const updatedList = prevList.map((post) =>
          post._id === postId
            ? { ...post, replies: [...post.replies, result.data] }
            : post
        );
        return updatedList;
      });

      setReplyText('');
      fetchAllReplies();
      window.location.reload()
    } catch (err) {
      console.error(err);
      // Handle other error scenarios if needed
    }
  };

  const fetchAllReplies = async () => {
    try {
      const response = await axios.get(`/api/getRepliesForSportPost/${postId}`);
      const allReplies = response.data;

      // Update the state with all replies
      setList((prevList) => {
        const updatedList = prevList.map((post) =>
          post._id === postId
            ? { ...post, replies: allReplies }
            : post
        );
        return updatedList;
      });
    } catch (error) {
      console.error('Error fetching all replies:', error);
    }
  };

  return (
    <div className="bg-div">
      <div className="rotate">
        <div className="font-quicksand flex min-h-full p-3 pt-5">
          <form
            className="m-auto bg-white drop-shadow-lg overflow-hidden w-full max-w-xl accent-gray-800"
            onSubmit={handleSubmit}
          >
            <div className="p-6">
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <textarea
                  className="w-full h-20 bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800 resize-none"
                  placeholder="Add a reply..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                />
                {errors.replyText && (
                  <p className="text-red-600 text-center">{errors.replyText.message}</p>
                )}
              </div>

              <input type="hidden" name="userId" value={loggedUser1._id} />
            </div>

            <div>
              <button
                className="bg-[#325490] uppercase py-4 w-full font-quicksand text-white font-bold text-m tracking-widest"
                onClick={() => audio.play()}
              >
                Submit Reply
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSportReply;

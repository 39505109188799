// // Members.js
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate } from 'react-router-dom';
// import '../members.css';
// import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';
// import wmEntrance from '../assets/wmEntranceSq.jpg'

// const Members = () => {
//   const [list, setList] = useState([]);


//   const navigate = useNavigate();
//   const audio = new Audio(buttonSound);

//   useEffect(() => {
//     axios
//       .get('/api/users', { withCredentials: true })
//       .then((res) => {
//         console.log(res);
//         setList(res.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);



  
//   return (
//     <div>
//     <div className="members">
//       <span className="memberTitle">Wyomissing Meadows Clubhouse Members</span>
//       <div className="memberPara">
//         {list
//           .slice()
//           .sort((a, b) => a.lastName.localeCompare(b.lastName))
//           .map((user, i) => (
//             <div className="userName" key={i}>
//               <p >
//                 {user.firstName} {user.lastName}
//               </p>
//             </div>
//           ))}
//       </div>

//     </div>
//     </div>
//   );
// };

// export default Members;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../members.css';
import {Link, useParams} from 'react-router-dom';

const Members = () => {
  const [list, setList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // useEffect(() => {
  //   axios
  //     .get('/api/users', { withCredentials: true })
  //     .then((res) => {
  //       setList(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get('/api/users', { withCredentials: true })
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


//   return (
//     <div className="members">
//       <span className="memberTitle">Wyomissing Meadows Clubhouse Members</span>
//       <div className="memberPara">

//         {list
//           .slice()
//           .sort((a, b) => a.lastName.localeCompare(b.lastName))
//           .map((user, i) => (
//             <div className="userName" key={user.id || i} >
//               {/* Use user.id as the key if available, or fallback to index (i) */}
//               <Link to={`/user/${user._id}`}>{user.firstName} {user.lastName}</Link>
//             </div>
//           ))}
//       </div>

//     </div>
//   );
// };

// export default Members;

return (
  <div className="members">
    <h2 className="memberTitle">Wyomissing Meadows Clubhouse Members</h2>
    <div className='memberBox'>
      <div className="memberPara">
        {list
          .slice()
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .map((user, i) => (
            <div className="userName" key={user.id || i}>
              {/* Use user.id as the key if available, or fallback to index (i) */}
              <Link to={`/user/${user._id}`}>{user.firstName} {user.lastName}</Link>
            </div>
          ))}
      </div>
    </div>
  </div>
);
};

export default Members;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../weather.css'
const DailyWeather = () => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get('https://api.weather.gov/gridpoints/CTP/61,45/forecast');
        setWeatherData(response.data.properties.periods[0]);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    fetchWeatherData();
  }, []);

  const renderWeatherIcon = () => {
    if (!weatherData) {
      return <div>Loading...</div>;
    }

    const iconUrl = weatherData.icon.replace(/^http:/, 'https:'); // Assuming the API provides an icon URL

    return <img src={iconUrl} alt="Weather Icon" />;
  };

  const renderWeather = () => {
    if (!weatherData) {
      return <div>Loading...</div>;
    }

    const { temperature, shortForecast, detailedForecast } = weatherData;

    return (
      <div className="daily-weather-container">
        <div className="weather-details">
        <p className='weatherDeets'>{temperature}&deg;F{' '}&nbsp;{shortForecast}</p>
          <div className="weather-info">
            <p>{detailedForecast}</p>
          </div>
          <div className="weather-icon">{renderWeatherIcon()}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderWeather()}
    </div>
  );
};

export default DailyWeather;

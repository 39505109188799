import React from 'react'
import Register from './Register'
import '../logreg.css'
import { useState } from 'react';
const Reg = () => {

    return (
        <div className='logRegForms'>
            <div>
                <Register />
            </div>
        </div>
      )
    }

export default Reg
import React from 'react'
import {Link,NavLink, useNavigate} from 'react-router-dom';
import '../footer.css';
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav'

const Footer = () => {

  const emailAddress = 'wmhoa2024@gmail.com';

  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  return (
    <div className='footer flex-col items-center lg:flex-row lg:justify-around'>
        <span className='footerBg' >© 2024 PG TECH CREATIONS&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;<a className='footerContact'
                                          href={`mailto:${emailAddress}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() => audio.play()}
                                      >
                                          CONTACT
                                      </a>
                                      </span>

    </div>
  )
}

export default Footer
import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';

import '../home.css';
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';

const Home = () => {
  const navigate = useNavigate();
  const audio = new Audio(buttonSound);
  const [showArrow, setShowArrow] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      // Adjust the threshold value as needed
      const threshold = 100;

      if (scrolled > threshold) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="welcome-section">
        <h1 className="homeTitle">Welcome to the Club.</h1>
        <Link to="connection-section" smooth={true} duration={500}>
          <div className="scroll-arrow">&#8595;</div>
        </Link>
      </div>

      <div id="connection-section" className="connection-section">
        {showArrow && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            <FaArrowUp className="arrow-icon" />
          </div>
        )}
        <h2 className="homeTitle2">Connection Made Simple</h2>
        <div className="paraBox">
          <p className="homePara">
            The Clubhouse serves as your centralized platform to engage in community discussions, organize activities like golf rounds and book clubs, share events, and foster community connections.
          </p>
        </div>
        <button
          className="homeButton"
          onClick={() => {
            audio.play();
            navigate('/login');
          }}
        >
          Let's Connect
        </button>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../userDash.css";
import AddBbPost from './AddBbPost';
import AddBbReply from './AddBbReply';
import thumbtack from '../assets/thumbtack.svg'

const BulletinBoard = () => {
  const [list, setList] = useState([]);
  const [repliesMap, setRepliesMap] = useState({}); // Use a map to store replies for each post

  const localUser = localStorage.getItem('loggedUser');
  const loggedUser1 = JSON.parse(localUser);
  const { id } = useParams();
  const navigate = useNavigate();

  const filterExpiredPosts = (bulletinPosts) => {
    const currentTime = new Date().getTime();
    return bulletinPosts.filter((bulletinPost) => {
      const bulletinPostedTime = new Date(bulletinPost.postedOn).getTime();
      const daysDifference = (currentTime - bulletinPostedTime) / (1000 * 60 * 60 * 24);
      return daysDifference <= 90;
    });
  };

  useEffect(() => {
    const fetchAndFilterBulletinPosts = async () => {
      try {
        const res = await axios.get('/api/getAllBulletinBoards', {
          withCredentials: true,
        });
        console.log('Fetched Bulletin Board Posts:', res.data);

        const sortedList = res.data.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
        const nonExpiredBulletinPosts = filterExpiredPosts(sortedList);
        setList(nonExpiredBulletinPosts);

       // Fetch replies for each post and store them in the repliesMap
       const promises = nonExpiredBulletinPosts.map(async (post) => {
        try {
          const response = await axios.get(`/api/getRepliesForPost/${post._id}`);
          setRepliesMap((prevRepliesMap) => ({
            ...prevRepliesMap,
            [post._id]: response.data,
          }));
        } catch (error) {
          console.error('Error fetching replies:', error);
        }
      });

      await Promise.all(promises);
    } catch (err) {
      console.log(err);
    }
  };

    fetchAndFilterBulletinPosts();

    const interval = setInterval(() => {
      fetchAndFilterBulletinPosts();
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const deleteBulletinPost = (id) => {
    const confirmation = window.confirm('Are you sure you want to delete this bulletin board post?');
    if (confirmation) {
      axios
        .delete(`/api/deleteBulletinBoard/${id}`)
        .then((res) => {
          console.log('BB post deleted', res.data);
          setList((prevList) => prevList.filter((bulletinPost) => bulletinPost._id !== id));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getRepliesForPost = async (postId) => {
    try {
      const response = await axios.get(`/api/getRepliesForPost/${postId}`);
      console.log('Replies for Post:', response.data);
      // Handle the replies data as needed
    } catch (error) {
      console.error('Error fetching replies:', error);
    }
  };

  return (
    <div className="user-bulletin-container">
      <h2 className="bbTitle">Bulletin Board</h2>
      <div className="bulletinSide">
        

      <div className="forumBoxBb">
                {list.map((bulletinPost, index) => (
                    <div className="forumPostBb" key={`post_${bulletinPost._id}_${index}`}>
                        <div className="postHeaderBb">
                            <div className="posterInfoBb">
                                <span>
                                    {bulletinPost.createdBy && bulletinPost.createdBy.firstName} {' '}
                                    {bulletinPost.createdBy && bulletinPost.createdBy.lastName} {' '}
                                    posted on {' '} {formatDate(bulletinPost.postedOn)}
                                </span>
                            </div>
                            <img src={thumbtack} alt="The Clubhouse"></img>
                            {loggedUser1 && loggedUser1.email === bulletinPost.createdBy?.email && (
                                <span className="clickText" onClick={() => deleteBulletinPost(bulletinPost._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="postContentBb">
                            {bulletinPost.bulletinPost}
                        </div>
                    
                    </div>
                ))}
            </div>
        </div>
      </div>


  );
};

export default BulletinBoard;

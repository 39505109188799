import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState([]);

    const navigate = useNavigate();
    const audio = new Audio(buttonSound);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);

        try {
            let response = await axios.post('/api/login', {
                email,
                password
            }, { withCredentials: true });

            let logged = await axios.get("/api/loggedUser", { withCredentials: true });
            console.log(`Email is: ${logged.data.email}`);
            localStorage.setItem('loggedUser', JSON.stringify(logged.data));
            console.log(`Logged in user is: ${logged.data.firstName}`);
            navigate('/dashboard');
        } catch (err) {
            console.log('errorResponse:', err.response);
            console.log('errorResponse:', err.response.data); // Log the entire error response
            const errorResponse = err.response.data.message;
            setErrors([errorResponse]);
            console.log('error');
        }
    };

    return (
        <div className="bg-div">
            <div className="rotate">
                <div className="font-quicksand flex min-h-full p-3 pt-5">
                    <form
                        className="m-auto bg-white drop-shadow-lg overflow-hidden w-full max-w-xl accent-gray-800"
                        onSubmit={handleSubmit}
                    >
                        <div className="p-6">
                            <p className="text-3xl pl-3 text-[#012753]">User Login</p>
                            <span className="text-sm italic text-gray-800">*Email & password are case sensitive</span>
                            <div className="mt-4 relative">
                                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                                </div>
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    className=" w-full bg-[#AABFD7] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                                    placeholder="Email"
                                    type="text"

                                />
                            </div>
                            <div className="mt-4 relative">
                                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                                    
                                </div>
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    className=" w-full bg-[#AABFD7] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                                    placeholder="Password"
                                    type={showPassword ? "text" : "password"}

                                />
                                <span
                                    className="absolute top-0 right-0 mr-4 mt-3 cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </span>
                            </div>
                        </div>
                        {errors.map((error, index) => (
                            <p key={index} className="text-red-600 text-center mb-2">{error}</p>
                        ))}
                        <div>
                            <button
                                className="bg-[#325490] font-quicksand font-bold uppercase py-4 w-full text-white text-m tracking-widest"
                                value="submit"
                                onClick={() => audio.play()}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;

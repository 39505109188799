
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../forum.css";
import AddBookPost from './AddBookPost';
import AddBookReply from './AddBookReply';
import Modal from './Modal';


const ChatBook = () => {
    const [list, setList] = useState([]);
    const [repliesMap, setRepliesMap] = useState({});
    const [repliesVisibility, setRepliesVisibility] = useState({});
    const [showAddPostModal, setShowAddPostModal] = useState(false);
    const [showAddReplyModal, setShowAddReplyModal] = useState(false);
    const [newPostContent, setNewPostContent] = useState('');
    const [selectedPostId, setSelectedPostId] = useState(null);


    const localUser = localStorage.getItem('loggedUser');
    const loggedUser1 = JSON.parse(localUser);
    const { id } = useParams();
    const navigate = useNavigate();


    
    const filterExpiredPosts = (bookPosts) => {
        const currentTime = new Date().getTime();
        return bookPosts.filter((bookPost) => {
            const bookPostedTime = new Date(bookPost.postedOn).getTime();
            const daysDifference = (currentTime - bookPostedTime) / (1000 * 60 * 60 * 24);
            return daysDifference <= 90;
        });
    };

    const toggleRepliesVisibility = (postId) => {
        setRepliesVisibility((prevVisibility) => ({
            ...prevVisibility,
            [postId]: !prevVisibility[postId],
        }));
    };

    useEffect(() => {
        const fetchAndFilterBookPosts = async () => {
            try {
                const res = await axios.get('/api/getAllChatBooks', { withCredentials: true });
                const sortedList = res.data.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
                const nonExpiredBookPosts = filterExpiredPosts(sortedList);
                setList(nonExpiredBookPosts);

                const promises = nonExpiredBookPosts.map(async (post) => {
                    try {
                        const response = await axios.get(`/api/getRepliesForBookPost/${post._id}`);
                        setRepliesMap((prevRepliesMap) => ({
                            ...prevRepliesMap,
                            [post._id]: response.data,
                        }));
                        setRepliesVisibility((prevVisibility) => ({
                            ...prevVisibility,
                            [post._id]: false,
                        }));
                    } catch (error) {
                        console.error('Error fetching replies:', error);
                    }
                });

                await Promise.all(promises);
                    
            } catch (err) {
                console.log(err);
            }
        };

        fetchAndFilterBookPosts();

        const interval = setInterval(() => {
            fetchAndFilterBookPosts();
        }, 24 * 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);


    const deleteBookPost = (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this post?');
        if (confirmation) {
            axios
                .delete(`/api/deleteChatBook/${id}`)
                .then((res) => {
                    console.log('BB post deleted', res.data);
                    setList((prevList) => prevList.filter((bookPost) => bookPost._id !== id));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleAddPost = () => {
        setShowAddPostModal(true);
    };

    const handleAddReply = (postId) => {
        setSelectedPostId(postId);
        setShowAddReplyModal(true);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getRepliesForBookPost = async (postId) => {
        try {
            const response = await axios.get(`/api/getRepliesForBookPost/${postId}`);
            console.log('Replies for Post:', response.data);
        } catch (error) {
            console.error('Error fetching replies:', error);
        }
    };


//     const { setLatestBookPost } = useLatestBookPost();

// const handleAddPostFormSubmit = async (event) => {
//     event.preventDefault();
//     try {
//         const response = await axios.post('/api/createChatBook');
//         setLatestBookPost(response.data); // Update the latestBookPost state using context
//         // Other logic...
//     } catch (error) {
//         console.error('Error adding book post:', error);
//     }
// };

    // const handleAddPostFormSubmit = (event) => {
    //     event.preventDefault();
    //     // Handle form submission for adding a post
    //     setShowAddPostModal(false);
    //     // Call API to add the post
        

    // };


// const handleAddPostFormSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       // Make API call to add the post
//       const response = await axios.post('/api/createChatBook');
//       // Update the latestBookPost state in the parent component
//       localStorage.setItem.setLatestBookPost(response.data);
//       // Other logic...
//     } catch (error) {
//       console.error('Error adding book post:', error);
//     }
//   };

const handleAddPostFormSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post('/api/createChatBook');
        localStorage.setItem('latestBookPost', JSON.stringify(response.data)); // Set the latest book post in localStorage
        // Other logic...
    } catch (error) {
        console.error('Error adding book post:', error);
    }
};


    const handleAddReplyFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a reply
        setShowAddReplyModal(false);
        // Call API to add the reply
    };

    const deleteReply = (postId, replyId) => {
        const confirmation = window.confirm('Are you sure you want to delete this reply?');
        if (confirmation) {
            axios
                .delete(`/api/deleteChatBookReply/${replyId}`)
                .then((res) => {
                    console.log('Reply deleted', res.data);
                    setRepliesMap((prevRepliesMap) => ({
                        ...prevRepliesMap,
                        [postId]: prevRepliesMap[postId].filter((reply) => reply._id !== replyId),
                    }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="forumMain">
            <h2 className="forumTitle">Book Club</h2>
            <p className="forumTitle2">Read&nbsp;&nbsp;|&nbsp; &nbsp;Discuss&nbsp;&nbsp;|&nbsp;&nbsp;Connect</p>
            <button onClick={handleAddPost}
                className="forumButton bg-[#325490] mt-4 py-4 w-full font-quicksand text-white text-m tracking-widest"
                type="submit"
              >
                ADD POST
              </button>

            <Modal show={showAddPostModal} onClose={() => setShowAddPostModal(false)}>
                    <AddBookPost onAddPost={handleAddPost} />
                    <button type="button" onClick={() => setShowAddPostModal(false)}>Cancel</button>
            </Modal>
            <div className="forumBox">
                {list.map((bookPost, index) => (
                    <div className="forumPost" key={`post_${bookPost._id}_${index}`}>
                        <div className="postHeader">
                            <div className="posterInfo">
                                <span>
                                    {bookPost.createdBy && bookPost.createdBy.firstName} {' '}
                                    {bookPost.createdBy && bookPost.createdBy.lastName} {' '}
                                    posted on {' '} {formatDate(bookPost.postedOn)}
                                </span>
                            </div>
                            {loggedUser1 && loggedUser1.email === bookPost.createdBy?.email && (
                                <span className="clickText" onClick={() => deleteBookPost(bookPost._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="postContent">
                            {bookPost.bookPost}
                        </div>
                        <button onClick={() => toggleRepliesVisibility(bookPost._id)}>
                            {repliesVisibility[bookPost._id] ? 'Hide Replies' : 'Show Replies'}
                        </button>
                        {repliesVisibility[bookPost._id] && (
                            <div className="repliesContainer">
                    <strong>Replies:</strong>
            {repliesMap[bookPost._id] && repliesMap[bookPost._id].length > 0 ? (
            <ul>
                {repliesMap[bookPost._id].map((reply, index) => (
                    <li className='replySinglePost' key={`reply_${bookPost._id}_${reply._id || index}`}>
                        <div className="replyHeader">
                            <span className="replyPosterInfo">
                                {reply.createdBy.firstName} {reply.createdBy.lastName} replied on{' '}
                                {reply.createdAt ? formatDate(reply.createdAt) : 'N/A'}
                            </span>
                            {loggedUser1 && loggedUser1.email === reply.createdBy?.email && (
                                <span className="clickText2" onClick={() => deleteReply(bookPost._id, reply._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="replyContent">
                            <span className="replyTextForum">{reply.replyText}</span>
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <span className="replyTextForum">No replies yet.</span>
        )}
    </div>
)}
                        <AddBookReply
                            postId={bookPost._id}
                            setList={setList}
                            getRepliesForBookPost={getRepliesForBookPost}
                        />

                    </div>
                ))}
            </div>
            <div id="modal-root"></div>
        </div>
    );
};


export default ChatBook;

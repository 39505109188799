

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import buttonSound from '../assets/mixkit-plastic-bubble-click-1124.wav';

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [houseNum, setHouseNum] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [interests, setInterests] = useState([]);
  const [otherInterest, setOtherInterest] = useState("");
  const [showOtherInterestInput, setShowOtherInterestInput] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const audio = new Audio(buttonSound);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
  
    try {
      let newUser = {
        firstName,
        lastName,
        email,
        houseNum,
        phoneNum,
        interests: showOtherInterestInput
          ? [...interests, otherInterest]
          : interests,
        password,
        confirmPassword,
      };
  
      const reg = await axios.post(
        "/api/register",
        newUser,
        { withCredentials: true }
      );
      console.log("registered: ", reg.data);
  
      const login = await axios.post(
        "/api/login",
        {
          email,
          password
        },
        { withCredentials: true }
      );
      console.log(login.data);
      let logged = await axios.get("/api/loggedUser", {
        withCredentials: true
      });
      localStorage.setItem("loggedUser", JSON.stringify(logged.data));
      console.log(`Logged in user is: ${logged.data.firstName}`);
      alert(
        `Thanks for registering and welcome to the Wyomissing Meadows Clubhouse! Please login to access your personal dashboard.`
      );
      navigate("/login");
      setFirstName("");
      setLastName("");
      setEmail("");
      setHouseNum("");
      setPhoneNum("");
      setInterests([]);
      setOtherInterest("");
      setShowOtherInterestInput(false);
      setPassword("");
      setConfirmPassword("");
    } catch (err) {
      console.log("comes from register route");
      console.log(err.response.data);
      if (err.response && err.response.data && err.response.data.errors) {
        const errorObj = {};
        err.response.data.errors.forEach(error => {
          errorObj[error.field] = { message: error.message };
        });
        setErrors(errorObj);
      } else {
        console.error("Unexpected error:", err);
      }
    }
    

  };
 

  return (
    <div className="bg-div">
      <div className="rotate">
        <div className="font-quicksand flex min-h-full p-3 pt-5">
          <form
            className="m-auto bg-white drop-shadow-lg  overflow-hidden w-full max-w-xl accent-gray-800"
            onSubmit={handleSubmit}
          >
            <div className="p-6">
              <p className="text-3xl pl-3 text-[#012753]">Register New User</p>
              <span className="text-sm italic text-gray-800">*Email & password are case sensitive</span>

              {/* FIRST NAME */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="First Name"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                  <p className="text-red-600 text-center">
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              {/* LAST NAME */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Last Name"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && (
                  <p className="text-red-600 text-center">
                    {errors.lastName.message}{" "}
                  </p>
                )}
              </div>

{/* HOUSE NUMBER */}
<div className="mt-4 relative">
  <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
  </div>
  <input
    className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
    placeholder="House Number"
    type="number" // Change type to "number"
    value={houseNum}
    onChange={(e) => setHouseNum(e.target.value)}
  />
  {errors.houseNum && (
    <p className="text-red-600 text-center">
      {errors.houseNum.message}{" "}
    </p>
  )}
</div>

              {/* EMAIL */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <p className="text-red-600 text-center">
                    {errors.email.message}{" "}
                  </p>
                )}
              </div>


              {/* PHONE NUMBER */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Phone Number (Optional)"
                  type="text"
                  value={phoneNum}
                  onChange={(e) => setPhoneNum(e.target.value)}
                />
              </div>

              {/* INTERESTS */}
              <div className="mt-4">
                <label className="block text-sm font-bold text-gray-700">Interests</label>
                <div className="flex flex-wrap gap-4">
                  {['Cooking/Baking', 'Health & Wellness', 'Reading & Books', 'Outdoor Recreation', 'Sports Enthusiast', 'Home Improvement', 'Gardening', 'Travel'].map((interest) => (
                    <div key={interest} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`interest_${interest}`}
                        name="interests"
                        value={interest}
                        checked={interests.includes(interest)}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setInterests((prevInterests) => {
                            if (checked) {
                              return [...prevInterests, interest];
                            } else {
                              return prevInterests.filter((item) => item !== interest);
                            }
                          });
                        }}
                      />
                      <label htmlFor={`interest_${interest}`} className="ml-2">
                        {interest}
                      </label>
                    </div>
                  ))}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="interest_other"
                      name="interests"
                      checked={showOtherInterestInput}
                      onChange={() => setShowOtherInterestInput(!showOtherInterestInput)}
                    />
                    <label htmlFor="interest_other" className="ml-2">
                      Other
                    </label>
                    {showOtherInterestInput && (
                      <input
                        type="text"
                        className="ml-2 w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                        placeholder="Enter other interests"
                        value={otherInterest}
                        onChange={(e) => setOtherInterest(e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>

             {/* PASSWORD */}
             <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* Toggle visibility icon */}
                <span
                  className="absolute top-0 right-0 mr-4 mt-3 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </span>
                {errors.password && (
                  <p className="text-red-600 text-center">
                    {errors.password.message}{" "}
                  </p>
                )}
              </div>

              {/* CONFIRM PASSWORD */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {/* Toggle visibility icon */}
                <span
                  className="absolute top-0 right-0 mr-4 mt-3 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </span>
                {password !== confirmPassword && (
                  <p className="text-red-600 text-center">
                    Passwords must match.
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                className="bg-[#325490] uppercase py-4 w-full font-quicksand text-white font-bold text-m tracking-widest"
                onClick={() => audio.play()}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;

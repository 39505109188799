

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const EditUser = ({ onProfileUpdate }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loggedUser, setLoggedUser] = useState({
    firstName: '',
    lastName: '',
    phoneNum: '',
    interests: [],
    otherInterest: '',
  });

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/api/user/${id}`, {
          withCredentials: true,
        });

        const user = response.data;
        setLoggedUser({
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNum: user.phoneNum || '',
          interests: user.interests || [],
          otherInterest: user.otherInterest || '',
        });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchUserData();
  }, [id]);

  const updateUserProfile = async (e) => {
    e.preventDefault();

    try {
      const updatedUser = {
        id,
        firstName: loggedUser.firstName,
        lastName: loggedUser.lastName,
        phoneNum: loggedUser.phoneNum,
        interests: loggedUser.interests,
        otherInterest: loggedUser.otherInterest,
      };

      await axios.put(`/api/update/${id}`, updatedUser, {
        withCredentials: true,
      });

      // Fetch the updated user data
    const response = await axios.get(`/api/user/${id}`, {
      withCredentials: true,
    });

    const updatedUserData = response.data;

    // Update the state with the new data
    setLoggedUser({
      firstName: updatedUserData.firstName,
      lastName: updatedUserData.lastName,
      phoneNum: updatedUserData.phoneNum || '',
      interests: updatedUserData.interests || [],
      otherInterest: updatedUserData.otherInterest || '',
    });

    // Trigger the callback function to notify the parent component
    onProfileUpdate();


      alert('Profile updated successfully!');
      navigate(`/user/${id}`);
    } catch (error) {
      console.error('Failed to update profile:', error);
      setErrors([...errors, { msg: 'Failed to update profile.' }]);
    }
  };

  const handleCheckboxChange = (interest) => {
    setLoggedUser((prevUserData) => {
      const isChecked = prevUserData.interests.includes(interest);
      if (isChecked) {
        return {
          ...prevUserData,
          interests: prevUserData.interests.filter((item) => item !== interest),
        };
      } else {
        return { ...prevUserData, interests: [...prevUserData.interests, interest] };
      }
    });
  };

  return (
    <div className="bg-div">
      <div className="rotate">
        <div className="font-quicksand flex min-h-full p-3 pt-5">
          <form
            className="m-auto bg-white drop-shadow-lg overflow-hidden w-full max-w-xl accent-gray-800"
            onSubmit={updateUserProfile}
          >
            <div className="p-6">
              <p className="text-3xl pl-3 text-[#012753]">Edit My Profile</p>

              {/* FIRST NAME */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="First Name"
                  type="text"
                  value={loggedUser.firstName}
                  onChange={(e) => setLoggedUser({ ...loggedUser, firstName: e.target.value })}
                />
              </div>

              {/* LAST NAME */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Last Name"
                  type="text"
                  value={loggedUser.lastName}
                  onChange={(e) => setLoggedUser({ ...loggedUser, lastName: e.target.value })}
                />
              </div>

              {/* PHONE NUMBER */}
              <div className="mt-4 relative">
                <div className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center">
                </div>
                <input
                  className="w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
                  placeholder="Phone Number (Optional)"
                  type="text"
                  value={loggedUser.phoneNum}
                  onChange={(e) => setLoggedUser({ ...loggedUser, phoneNum: e.target.value })}
                />
              </div>

              {/* INTERESTS */}
              <div className="mt-4">
                <label className="block text-sm font-bold text-gray-700">Interests</label>
                <div className="flex flex-wrap gap-4">
                  {['Cooking/Baking', 'Health & Wellness', 'Reading & Books', 'Outdoor Recreation', 'Sports Enthusiast', 'Home Improvement', 'Gardening', 'Travel'].map((interest) => (
                    <div key={interest} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`interest_${interest}`}
                        name="interests"
                        value={interest}
                        checked={loggedUser.interests.includes(interest)}
                        onChange={() => handleCheckboxChange(interest)}
                      />
                      <label htmlFor={`interest_${interest}`} className="ml-2">
                        {interest}
                      </label>
                    </div>
                  ))}
                  <div className="flex items-center">
      <input
        type="checkbox"
        id="interest_other"
        name="interests"
        checked={loggedUser.otherInterest !== ''}
        onChange={() =>
          setLoggedUser((prevUserData) => ({
            ...prevUserData,
            otherInterest: prevUserData.otherInterest ? '' : 'Other Interest',
          }))
        }
      />
      <label htmlFor="interest_other" className="ml-2">
        Other
      </label>
      {loggedUser.otherInterest && (
        <input
          type="text"
          className="ml-2 w-full bg-[#AABFD7] text-[#012753] text-s font-bold border-none py-2 pl-8 pr-4 rounded placeholder:text-gray-800"
          placeholder={loggedUser.otherInterest === 'Other Interest' ? '' : 'Enter other interests'}
          value={loggedUser.otherInterest === 'Other Interest' ? '' : loggedUser.otherInterest}
          onChange={(e) => setLoggedUser({ ...loggedUser, otherInterest: e.target.value })}
        />
      )}
                  </div>
                </div>
              </div>
              <input type="hidden" name="userId" value={loggedUser._id} />
            </div>

            <div>
              <button
                className="bg-[#325490] uppercase py-4 w-full font-quicksand text-white font-bold text-m tracking-widest"
                type="submit"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useEffect, useState } from 'react';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Log from './components/Log';
import Reg from './components/Reg';
import UserDashboard from './components/UserDashboard';
import Members from './components/Members';
import UserProfileForm from './components/UserProfileForm';
import UserProfile from './components/UserProfile';
import ViewUser from './components/ViewUser';
import Todoform from './components/Todoform';
import ChatterboxHome from './components/ChatterboxHome';
import ChatBook from './components/ChatBook';
import ChatHealth from './components/ChatHealth';
import ChatHobbies from './components/ChatHobbies';
import ChatKitchen from './components/ChatKitchen';
import ChatMisc from './components/ChatMisc';
import ChatNeighbor from './components/ChatNeighbor';
import ChatRec from './components/ChatRec'
import ChatSport from './components/ChatSport'
import ChatTravel from './components/ChatTravel'
import ChatSell from './components/ChatSell';


import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import BulletinBoard from './components/BulletinBoard';
import BulletinBoardChatter from './components/BulletinBoardChatter';

function App() {

  const localUser = localStorage.getItem('loggedUser')
  const loggedUser1 = JSON.parse(localUser)


  return (
    <div className="App">
       <BrowserRouter>
          <Navbar />
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Log />} path="/login" />
            <Route element={<Reg />} path="/register" />
                <Route path="/" element={<ProtectedRoute />}>
                <Route element={<UserDashboard  />} path="/dashboard" />
                <Route element={<UserProfileForm  />} path="/addMemberProfile" />
                <Route path="/user/:id" element={<UserProfile />} />
                <Route path="/user/:userId" element={<ViewUser />} />
                <Route element={<Todoform  />} path="/addToDo" />
                <Route element={<BulletinBoard  />} />
                <Route element={<ChatterboxHome />} path="/chatterbox" />              
                <Route element={<BulletinBoardChatter  />} path="/chatterbox/bulletinBoard" />
                <Route element={<ChatBook />} path="/chatterbox/books" />
                <Route element={<ChatHealth  />} path="/chatterbox/health" />
                <Route element={<ChatHobbies />} path="/chatterbox/hobbies" />
                <Route element={<ChatKitchen />} path="/chatterbox/kitchen" />
                <Route element={<ChatMisc />} path="/chatterbox/miscellaneous" />
                <Route element={<ChatNeighbor />} path="/chatterbox/homeimprovement" />
                <Route element={<ChatRec />} path="/chatterbox/outdoorrec" />
                <Route element={<ChatSport />} path="/chatterbox/sports" />
                <Route element={<ChatTravel />} path="/chatterbox/travel" />
                <Route element={<ChatSell />} path="/chatterbox/marketplace" />
                <Route element={<Members  />} path="/members" />
                </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../forum.css";
import AddKitchenPost from './AddKitchenPost';
import AddKitchenReply from './AddKitchenReply';
import Modal from './Modal'; // Assuming you have a Modal component

const ChatKitchen = () => {
    const [list, setList] = useState([]);
    const [repliesMap, setRepliesMap] = useState({});
    const [repliesVisibility, setRepliesVisibility] = useState({});
    const [showAddPostModal, setShowAddPostModal] = useState(false);
    const [showAddReplyModal, setShowAddReplyModal] = useState(false);
    const [newPostContent, setNewPostContent] = useState('');
    const [selectedPostId, setSelectedPostId] = useState(null);

    const localUser = localStorage.getItem('loggedUser');
    const loggedUser1 = JSON.parse(localUser);
    const { id } = useParams();
    const navigate = useNavigate();

    const filterExpiredPosts = (kitchenPosts) => {
        const currentTime = new Date().getTime();
        return kitchenPosts.filter((kitchenPost) => {
            const kitchenPostedTime = new Date(kitchenPost.postedOn).getTime();
            const daysDifference = (currentTime - kitchenPostedTime) / (1000 * 60 * 60 * 24);
            return daysDifference <= 90;
        });
    };

    const toggleRepliesVisibility = (postId) => {
        setRepliesVisibility((prevVisibility) => ({
            ...prevVisibility,
            [postId]: !prevVisibility[postId],
        }));
    };

    useEffect(() => {
        const fetchAndFilterKitchenPosts = async () => {
            try {
                const res = await axios.get('/api/getAllChatKitchens', { withCredentials: true });
                const sortedList = res.data.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
                const nonExpiredKitchenPosts = filterExpiredPosts(sortedList);
                setList(nonExpiredKitchenPosts);

                const promises = nonExpiredKitchenPosts.map(async (post) => {
                    try {
                        const response = await axios.get(`/api/getRepliesForKitchenPost/${post._id}`);
                        setRepliesMap((prevRepliesMap) => ({
                            ...prevRepliesMap,
                            [post._id]: response.data,
                        }));
                        setRepliesVisibility((prevVisibility) => ({
                            ...prevVisibility,
                            [post._id]: false,
                        }));
                    } catch (error) {
                        console.error('Error fetching replies:', error);
                    }
                });

                await Promise.all(promises);
                                               // Check if there are new posts and set localStorage flag
                                               const currentPostCount = localStorage.getItem('kitchenPostCount') || 0;
                                               if (nonExpiredKitchenPosts.length > currentPostCount) {
                                                   localStorage.setItem('newKitchenPosts', 'true');
                                               }
                                               localStorage.setItem('kitchenPostCount', nonExpiredKitchenPosts.length);
            } catch (err) {
                console.log(err);
            }
        };

        fetchAndFilterKitchenPosts();

        const interval = setInterval(() => {
            fetchAndFilterKitchenPosts();
        }, 24 * 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const deleteKitchenPost = (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this post?');
        if (confirmation) {
            axios
                .delete(`/api/deleteChatKitchen/${id}`)
                .then((res) => {
                    console.log('Kitchen post deleted', res.data);
                    setList((prevList) => prevList.filter((kitchenPost) => kitchenPost._id !== id));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleAddPost = () => {
        setShowAddPostModal(true);
    };

    const handleAddReply = (postId) => {
        setSelectedPostId(postId);
        setShowAddReplyModal(true);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getRepliesForPost = async (postId) => {
        try {
            const response = await axios.get(`/api/getRepliesForKitchenPost/${postId}`);
            console.log('Replies for Post:', response.data);
        } catch (error) {
            console.error('Error fetching replies:', error);
        }
    };

    const handleAddPostFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a post
        setShowAddPostModal(false);
        // Call API to add the post
    };

    const handleAddReplyFormSubmit = (event) => {
        event.preventDefault();
        // Handle form submission for adding a reply
        setShowAddReplyModal(false);
        // Call API to add the reply
    };

    const deleteReply = (postId, replyId) => {
        const confirmation = window.confirm('Are you sure you want to delete this reply?');
        if (confirmation) {
            axios
                .delete(`/api/deleteChatKitchenReply/${replyId}`)
                .then((res) => {
                    console.log('Reply deleted', res.data);
                    setRepliesMap((prevRepliesMap) => ({
                        ...prevRepliesMap,
                        [postId]: prevRepliesMap[postId].filter((reply) => reply._id !== replyId),
                    }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="forumMain">
            <h2 className="forumTitle">Cooking & Baking</h2>
            <p className="forumTitle2">Sweet&nbsp;&nbsp;|&nbsp; &nbsp;Savory&nbsp;&nbsp;|&nbsp;&nbsp;Spicy</p>
            <button onClick={handleAddPost}
                className="forumButton bg-[#325490] mt-4 py-4 w-full font-quicksand text-white text-m tracking-widest"
                type="submit"
              >
                ADD POST
              </button>

            <Modal show={showAddPostModal} onClose={() => setShowAddPostModal(false)}>
                    <AddKitchenPost />
                    <button type="button" onClick={() => setShowAddPostModal(false)}>Cancel</button>
            </Modal>
            <div className="forumBox">
                {list.map((kitchenPost, index) => (
                    <div className="forumPost" key={`post_${kitchenPost._id}_${index}`}>
                        <div className="postHeader">
                            <div className="posterInfo">
                                <span>
                                    {kitchenPost.createdBy && kitchenPost.createdBy.firstName} {' '}
                                    {kitchenPost.createdBy && kitchenPost.createdBy.lastName} {' '}
                                    posted on {' '} {formatDate(kitchenPost.postedOn)}
                                </span>
                            </div>
                            {loggedUser1 && loggedUser1.email === kitchenPost.createdBy?.email && (
                                <span className="clickText" onClick={() => deleteKitchenPost(kitchenPost._id)}>
                                    delete
                                </span>
                            )}
                        </div>
                        <div className="postContent">
                            {kitchenPost.kitchenPost}
                        </div>
                        <button onClick={() => toggleRepliesVisibility(kitchenPost._id)}>
                            {repliesVisibility[kitchenPost._id] ? 'Hide Replies' : 'Show Replies'}
                        </button>
                        {repliesVisibility[kitchenPost._id] && (
                            <div className="repliesContainer">
                    <strong>Replies:</strong>
            {repliesMap[kitchenPost._id] && repliesMap[kitchenPost._id].length > 0 ? (
            <ul>
                {repliesMap[kitchenPost._id].map((reply, index) => (
                    <li className='replySinglePost' key={`reply_${kitchenPost._id}_${reply._id || index}`}>
                        <div className="replyHeader">
                            <span className="replyPosterInfo">
                                {reply.createdBy.firstName} {reply.createdBy.lastName} replied on{' '}
                                {reply.createdAt ? formatDate(reply.createdAt) : 'N/A'}
                            </span>
                            {loggedUser1 && loggedUser1.email === reply.createdBy?.email && (
                                <span className="clickText2" onClick={() => deleteReply(kitchenPost._id, reply._id)}>
                                    delete
                                </span>
                            )}
                        </div>

                        <div className="replyContent">
                            <span className="replyTextForum">{reply.replyText}</span>
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <span className="replyTextForum">No replies yet.</span>
        )}
    </div>
)}
                        <AddKitchenReply
                            postId={kitchenPost._id}
                            setList={setList}
                            getRepliesForPost={getRepliesForPost}
                        />
                    </div>
                ))}
            </div>
            <div id="modal-root"></div>
        </div>
    );
};


export default ChatKitchen;
